<template>
    <div class="menus">
        <v-row style="max-width: 1200px" class="mx-auto" justify="center">
            <v-col cols="8">
                <br />
                <br />

                <h1 class="main-header">Сайт за 60 секунд для меню, акций, презентаций, каталога, визиток и др.</h1>

                <br />

                <h2>Обновление для партнеров Darall.</h2>

                <p>Загрузите <b>меню в PDF</b> и получите <b>мини-сайт</b> с кнопкой для заказа уже сегодня.</p>

                <img class="menu-img w-50" src="../../assets/images/menu-1.png" />

                <br />

                <h2>Для кого эта новая возможность?</h2>
                <br />
                <p>1. Запустите электронное меню по QR-коду, чтобы облегчить процесс заказа вашим гостям;</p>
                <img class="menu-img w-100" src="../../assets/images/menu-2.png" />

                <br />
                <p>2. У вас нет сайта;</p>
                <img class="menu-img w-100" src="../../assets/images/menu-3.png" />

                <br />
                <p>3. У вас есть сайт, но хотите запустить отдельную акцию;</p>
                <img class="menu-img w-100" src="../../assets/images/menu-4.png" />

                <br />
                <p>4. У вас поменялось меню (цены в нем), и необходимо это отобразить;</p>
                <img class="menu-img w-100" src="../../assets/images/menu-5.png" />

                <br />
                <p>5. Вы хотите быстро протестировать идею <b>(лендинг).</b></p>

                <br />

                <h2>Сильные стороны:</h2>
                <br />
                <h3>Очень быстрый старт.</h3>
                <p>- Буквально в течение одного дня.</p>
                <br />

                <h3>Официант в телефоне. Продажи по телефону хороши тем, что</h3>
                <p>- Клиент может получить консультацию;</p>
                <p>- Ему можно допродать интересные вам позиции;</p>
                <p>- Есть отдельная категория, которая предпочитает именно этот способ заказа.</p>
                <br />

                <h3>Действие в один клик</h3>
                <p>- Как правило, чем больше этапов и кликов необходимо сделать гостю, тем большее вероятность, что он передумает - это статистика;</p>
                <p>- Одна большая кнопка с простым действием дает больше мотивации.</p>
                <br />

                <h2>Примеры ссылок и содержимого готовых сайтов с меню:</h2>
                <a href="https://menu.darall.pro/slukhi">https://menu.darall.pro/slukhi</a>
                <br />
                <a href="https://menu.darall.pro/chalet">https://menu.darall.pro/chalet</a>
                <br />
                <br />
                <br />

                <h2>Хотите запустить свое Смарт-меню/сайт бесплатно?</h2>
                <br />

                <p>Пишите в вашу группу поддержки в Telegram.</p>
                <img class="menu-img w-50" src="../../assets/images/menu-6.png" />

                <br />
                
                <br />
                <br />
            </v-col>
        </v-row>
    </div>
</template>

<script>
    // import config from '../../config'

    export default {
        name: 'menus',
        data() {
            return {}
        },
        mounted() {
            console.log('start menu!')
        },
        methods: {},
        computed: {},
    }
</script>

<style lang="stylus" scoped>
    .main-header
        text-align: center
    .menu-img
        text-align: center
        display: block
        margin: 0 auto
        max-height: 520px
    .w-50
        max-width: 50%
    .w-100
        max-width: 70%

</style>
